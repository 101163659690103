import { userAPI } from "./configs/axiosConfig"
import { defineCancelApiObject } from "./configs/axiosUtils"
import { USER_API_ROUTES } from "./routes"

export const usersAPI = {
    createUser: async function (user, cancel = false) {
        let res = await userAPI.request({
            url: USER_API_ROUTES.USER_CREATE,
            method: "POST",
            data: user,
            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        })
        return res
    },
    updateUser: async function (user, cancel = false) {
        let res = await userAPI.request({
            url: USER_API_ROUTES.USER_UPDATE,
            method: "POST",
            data: user,
            params: {
                authenticate: true
            },
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        })
        return res
    },
    sendOTP: async function (data, cancel = false) {
        let res = await userAPI.request({
            url: USER_API_ROUTES.USER_OTP_SEND,
            method: "POST",
            data: data,
            params: {
                authenticate: true
            },
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        })
        return res
    },
    verifyOTP: async function (data, cancel = false) {
        let res = await userAPI.request({
            url: USER_API_ROUTES.USER_OTP_VERIFY,
            method: "POST",
            data: data,
            params: {
                authenticate: true
            },
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        })
        return res
    },
    webinarRegister: async function (data, cancel = false) {
        let res = await userAPI.request({
            url: USER_API_ROUTES.USER_WEBINAR_REGISTER,
            method: "POST",
            data: data,
            params: {
                authenticate: true
            },
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        })
        return res
    },
    getUsersInArea: async function (data, cancel = false) {
        let res = await userAPI.request({
            url: USER_API_ROUTES.GET_USERS_IN_AREA,
            method: "POST",
            data: data,
            params: {
                authenticate: true
            },
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        })
        return res
    },
}

// defining the cancel API object for usersAPI
const cancelApiObject = defineCancelApiObject(usersAPI)

import {
  ACADEMY_PROGRESSION_COLLECTION,
  COURSE_CHAPTERS_COLLECTION,
  COURSE_COLLECTION,
  STUDENTS_COLLECTION,
} from "../../../AppConstants/CollectionConstants";
import { ErrCode_DocNotFound } from "../../../AppConstants/ErrorCodes";
import firebase, { firestore } from "../../../firebase/firebase";

const updateCourseProgressionProperty = async (
  studentId,
  courseId,
  data,
  property
) => {
  try {
    const id = `${courseId}_${studentId}`;
    if (!id || !property) throw new Error("Provide all arguments");
    let docRef = firestore.collection(ACADEMY_PROGRESSION_COLLECTION).doc(id);
    let serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    await firestore.runTransaction(async (t) => {
      let doc = await t.get(docRef);
      if (!doc.exists) {
        const error = {
          code: "NotValidId",
          message: "Doc does not exist",
        };

        throw error;
      }

      t.update(docRef, {
        [property]: data,
        updateTimestamp: serverTimestamp,
      });
    });
  } catch (error) {
    console.error("Error :: ", error);
    throw error;
  }
};

const updateChapterProgression = async ({
  id,
  studentId,
  chapterId,
  courseId,
  data,
  override = true,
  firstTimeCompleteCallback = () => {},
}) => {
  try {
    const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    const chapterRef = firestore
      .collection(COURSE_CHAPTERS_COLLECTION)
      .doc(chapterId);
    const studentRef = firestore.collection(STUDENTS_COLLECTION).doc(studentId);
    const progressionRef = firestore
      .collection(ACADEMY_PROGRESSION_COLLECTION)
      .doc(id);
    const courseRef = firestore.collection(COURSE_COLLECTION).doc(courseId);

    const chapterDoc = await chapterRef.get();
    if (!chapterDoc.exists) {
      let err = {
        code: ErrCode_DocNotFound,
        message: "Course Chapter Not found",
      };

      throw err;
    }

    const chapterData = chapterDoc.data();
    const score = chapterData["score"];

    const studentDoc = await studentRef.get();
    if (!studentDoc.exists) {
      let err = {
        code: ErrCode_DocNotFound,
        message: "Student Not found",
      };

      throw err;
    }

    let firstTimeComplete = false;

    await firestore.runTransaction(async (t) => {
      const progressionDoc = await t.get(progressionRef);
      if (!progressionDoc.exists) {
        let err = {
          code: ErrCode_DocNotFound,
          message: "Progression Not found",
        };

        throw err;
      }

      const progressionData = progressionDoc.data();

      // Check data
      let allotPoints = true;

      if (!data["completed"]) {
        allotPoints = false;
      }

      // Check points already alloted ?
      if (
        progressionData["completedChapters"] &&
        progressionData["completedChapters"].length &&
        progressionData["completedChapters"].includes(chapterId)
      ) {
        allotPoints = false;
      }

      if (
        progressionData["chapters"] &&
        progressionData["chapters"][chapterId] &&
        progressionData["chapters"][chapterId]["completed"] === true
      ) {
        data["completed"] = true;
        if (!override) {
          data = progressionData["chapters"][chapterId];
        }
      }

      firstTimeComplete =
        data.completed &&
        (!progressionData["chapters"] ||
          !progressionData["chapters"][chapterId] ||
          progressionData["chapters"][chapterId]["completed"] === false);

      let courseCompleted = false;
      if (data["completed"] === true) {
        const courseDoc = await t.get(courseRef);
        const courseData = courseDoc.data();
        if (courseData["completion"]) {
          if (courseData["completion"]["completed"] === true) {
            if (courseData["completion"]["chapterId"] === chapterId) {
              courseCompleted = true;
            }
          }
        }
      }

      // If allot points set data['pointsAlloted] to true
      if (allotPoints) {
        t.update(studentRef, {
          courses: courseCompleted
            ? firebase.firestore.FieldValue.arrayUnion(courseId)
            : studentDoc.data()["courses"] || [],
          coursesCompleted: courseCompleted
            ? firebase.firestore.FieldValue.increment(1)
            : studentDoc.data()["coursesCompleted"] || 0,
          overallScore: firebase.firestore.FieldValue.increment(score),
          updateTimestamp: serverTimestamp,
        });

        t.update(progressionRef, {
          earnedPoints: firebase.firestore.FieldValue.increment(score),
          completedChapters:
            firebase.firestore.FieldValue.arrayUnion(chapterId),
          [`chapters.${chapterId}`]: data,
          courseCompleted: courseCompleted
            ? courseCompleted
            : progressionData["courseCompleted"] || false,
          lastWatch: {
            id: chapterId,
          },
          updateTimestamp: serverTimestamp,
        });
      } else {
        t.update(progressionRef, {
          [`chapters.${chapterId}`]: data,
          courseCompleted: courseCompleted
            ? courseCompleted
            : progressionData["courseCompleted"] || false,
          lastWatch: {
            id: chapterId,
          },
          updateTimestamp: serverTimestamp,
        });
      }

      return;
    });

    if (firstTimeComplete) {
      firstTimeCompleteCallback();
    }
  } catch (error) {
    console.error("Error :: ", error);
    throw error;
  }
};

const _put = {
  updateCourseProgressionProperty,
  updateChapterProgression,
};

export default _put;

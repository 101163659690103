import CourseProgressionManager from ".";
import firebase, { firestore } from "../../../firebase/firebase";

const checkForContentAlreadyWatched = async (chaptersData = [], studentId) => {
  try {
    if (!chaptersData.length) {
      return;
    }

    chaptersData = chaptersData.filter((chapter) =>
      chapter["id"].includes("course-chapter")
    );

    if (!chaptersData.length) {
      return;
    }

    const allocatePointsForChapters = [];

    for (let chapter of chaptersData) {
      const { targetData } = chapter;
      if (!targetData) {
        continue;
      }

      const { collection, targetField, targetId, userField } = targetData;
      const docuementRef = firestore
        .collection(collection)
        .where(targetField, "==", targetId)
        .where(userField, "==", studentId);
      const documentDoc = await docuementRef.get();
      if (documentDoc.empty) {
        continue;
      }

      const docData = documentDoc.docs[0].data();
      if (!docData) {
        continue;
      }

      // Allocate Points
      allocatePointsForChapters.push(chapter);
    }

    if (!allocatePointsForChapters.length) {
      return;
    }

    for (let chapter of allocatePointsForChapters) {
      await CourseProgressionManager._put.updateChapterProgression({
        id: `${chapter["courseId"]}_${studentId}`,
        studentId: studentId,
        chapterId: chapter["id"],
        courseId: chapter["courseId"],
        data: {
          completed: true,
          completeDuration: 0,
          totalDuration: chapter["duration"],
          prealloted: true,
          updateTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
        },
        override: false,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

const post = {
  checkForContentAlreadyWatched,
};

export default post;

import { createContext, useState } from "react";
import VideoManager from "../../Managers/Video";
import {
  SourceType,
  SignedType,
  PREDEFINED_VIDEO_ROW_TYPE,
} from "../../AppConstants/TypeConstants";
import axios from "axios";
import SeriesManager from "../../Managers/Video/series";
import { DESCENDING, TIMESTAMP } from "../../AppConstants/SortConstants";

const DATA_LOAD_STATE = {
  partial: 0,
  complete: 1,
};

export const videoContext = createContext([]);

const tokenURL = `https://asia-south1-docflix-d8cab.cloudfunctions.net/fetchJWTTokens`;
const headers = {
  "Content-Type": "application/json;charset=UTF-8",
  "Access-Control-Allow-Origin": "*",
};

export const fetchVideoTokens = async (playbackId, video) => {
  try {
    const _options = {
      playbackId: playbackId,
      expiry: `1d`, //expiry of tokens
      viewdays: 31, //default 31days
    };
    const response = await axios.post(tokenURL, _options, { headers });
    const { status, data, views } = response.data;

    if (status.code === 200 && data) {
      return { data, views };
    } else {
      throw new Error("API Error");
    }
  } catch (error) {
    console.error("Error fetching video token :: ", error.message, error.stack);
    throw error.message || "Failed to fetch tokens";
  }
};

export default function VideoContextProvider(props) {
  const [rowVideos, setRowVideos] = useState({});
  const [top10Shows, setTop10Shows] = useState(null);
  const [playNextVid, setPlayNextVid] = useState(false);
  // {
  //   sectionId: {
  //     videos: [],
  //     lastDoc: {},
  //   },
  //   sectionId: {
  //     videos: [],
  //     lastDoc: {},
  //   },
  //   sectionId: {
  //     videos: [],
  //     lastDoc: {},
  //   },
  // }

  const updateDataInState = async (response, sectionId, loadState) => {
    let _data = [];

    if (response.length === 0 || response.data.length === 0) {
      return;
    }
    _data = await Promise.all(
      response?.data.map(async (video) => {
        if (video.sourceType === SourceType.SOURCE_MUX) {
          if (video.videoPolicy && video.videoPolicy === SignedType.SIGNED) {
            // let res = await fetchVideoTokens(video.playbackid, video);
            return {
              ...video,
              // tokens: res.data
            };
          } else {
            return video;
          }
        } else {
          return video;
        }
      })
    );
    setRowVideos((prev) => {
      let _videoArr = prev[sectionId]
        ? prev[sectionId].videos
          ? [...prev[sectionId].videos, ..._data]
          : _data
        : _data;

      let _dobj = {};
      _videoArr.forEach((v) => (_dobj[v.id] = v));
      return {
        ...prev,
        [sectionId]: {
          videos: [...Object.values(_dobj)],
          lastDocRef: response.lastDocRef,
          loadState,
        },
      };
    });
  };

  const getVideosByTag = async (fetchParameters) => {
    try {
      if (rowVideos[fetchParameters.sectionId]) {
        return;
      } //do not load data again
      let response = [];
      if ("mostViewed" in fetchParameters) {
        response = await VideoManager._get.fetchMostViewedVideos({});
      } else if ("seasonRow" in fetchParameters) {
        let res = await VideoManager._get.getEpisodesBySeason(
          fetchParameters.seasonID
        );
        response = { data: res };
      } else if (fetchParameters?.sectionId === "recommended_for_you") {
        response = await VideoManager._get.fetchVideoByUserSpeciality({
          speciality: fetchParameters.speciality,
        });
      } else if (fetchParameters?.sectionId === "you_may_also_like") {
        response = await VideoManager._get.fetchYouMayAlsoLike(
          fetchParameters.uid
        );
      } else {
        response = await VideoManager._get.getSortedVideos({
          tags: fetchParameters.tags,
          speciality: fetchParameters.speciality,
        });
      }

      updateDataInState(
        response,
        fetchParameters.sectionId,
        DATA_LOAD_STATE.partial
      );
    } catch (error) {}
  };

  const getVideosBySpeakerId = async (fetchParameters) => {
    try {
      if (rowVideos[fetchParameters.sectionId]) {
        return;
      } //do not load data again
      const response = await VideoManager._get.getVideosBySpeakerId(
        fetchParameters.speakers,
        fetchParameters.based_on
      );
      updateDataInState(
        response,
        fetchParameters.sectionId,
        DATA_LOAD_STATE.partial
      );
    } catch (error) {}
  };
  const getAllVideosBySpeakerId = async (fetchParameters) => {
    try {
      if (rowVideos[fetchParameters.sectionId]) {
        if (
          rowVideos[fetchParameters.sectionId].loadState ===
          DATA_LOAD_STATE.complete
        ) {
          return; // data has already been loaded full
        }
      }
      let lr = rowVideos[fetchParameters.sectionId]
        ? rowVideos[fetchParameters.sectionId].lastDocRef
        : null;
      const response = await VideoManager._get.getVideosBySpeakerId(
        fetchParameters.tags,
        fetchParameters.based_on,
        DESCENDING,
        lr,
        10,
        true
      );
      updateDataInState(
        response,
        fetchParameters.sectionId,
        DATA_LOAD_STATE.complete
      );
    } catch (error) {}
  };
  const getAllVideosByTag = async (fetchParameters) => {
    try {
      if (rowVideos[fetchParameters.sectionId]) {
        if (
          rowVideos[fetchParameters.sectionId].loadState ===
          DATA_LOAD_STATE.complete
        ) {
          return; // data has already been loaded full
        }
      }
      let lr = rowVideos[fetchParameters.sectionId]
        ? rowVideos[fetchParameters.sectionId].lastDocRef
        : null;

      let response = [];
      if ("mostViewed" in fetchParameters) {
        response = await VideoManager._get.fetchMostViewedVideos({
          lastDocRef: lr,
        });
      } else if (fetchParameters?.sectionId === "recommended_for_you") {
        response = await VideoManager._get.fetchVideoByUserSpeciality({
          speciality: fetchParameters.speciality,
          lastDocRef: lr,
        });
      } else if (fetchParameters?.sectionId === "you_may_also_like") {
        // console.log("youMayAlsoLike");
        // do not fetch more than 10 videos
      } else {
        response = await VideoManager._get.getSortedVideos({
          tags: fetchParameters.tags,
          based_on: TIMESTAMP,
          order: DESCENDING,
          lastDocRef: lr,
          limit: 10,
          ignoreLimit: true,
        });
      }

      updateDataInState(
        response,
        fetchParameters.sectionId,
        DATA_LOAD_STATE.complete
      );
    } catch (error) {}
  };

  const getPredefinedVideos = async (type) => {
    try {
      if (rowVideos[type]) {
        return;
      } //do not load data again
      let callResponse = null;
      switch (type) {
        case PREDEFINED_VIDEO_ROW_TYPE.RECENTLY_ADDED:
          callResponse = await VideoManager._get.getSortedVideos({
            tags: [],
            based_on: TIMESTAMP,
            order: DESCENDING,
            lastDocRef: null,
            limit: 4,
          });
          updateDataInState(callResponse, type, DATA_LOAD_STATE.partial);
          break;
        default:
          break;
      }
    } catch (error) {}
  };

  const getMultipleVideosData = (videosID_array = []) => {
    return new Promise(async (res, rej) => {
      try {
        const data = await Promise.all(
          videosID_array.map((videoId) =>
            VideoManager._get.getVideoById(videoId)
          )
        );
        res(data);
      } catch (error) {
        console.error(error);
        rej(error);
      }
    });
  };

  const getTop10Shows = async () => {
    let res = await SeriesManager._get.getTopShows();
    setTop10Shows(res);
  };

  return (
    <videoContext.Provider
      value={{
        playNextVid,
        setPlayNextVid,
        rowVideos,
        getVideosByTag,
        getAllVideosByTag,
        getPredefinedVideos,
        getVideosBySpeakerId,
        getAllVideosBySpeakerId,
        getMultipleVideosData,
        getTop10Shows,
        top10Shows,
      }}
    >
      {props.children}
    </videoContext.Provider>
  );
}

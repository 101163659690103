import { useState, useEffect, useContext } from "react";
import styles from "./index.module.scss";
import { isMobileDevice } from "../../utils/getPlatformInfo";
import brandLogo from "../../assets/images/brandLogo.png";
import close from "../../assets/images/close.png";
import { useLocation } from "react-router-dom";
import { BUNDLE_ID, iosAppStoreId } from "../../AppConstants/Constants";
import { AnalyticsContext } from "../../contexts/Analytics/AnalyticsContextProvider";
import { GET_APP_EVENT } from "../../Clevertap/EventNames.ts";
import { TRENDS_OPEN_ROUTE } from "../../AppConstants/Routes.js";

function updateStatusINSessionStorage() {
  window.sessionStorage.setItem("app_redirect_modal", true);
}

const AppInstallPopUp = () => {
  const { addGAWithNoUser, addCleverTapAnalytics } =
    useContext(AnalyticsContext);
  const [showToast, setShowNetworkToast] = useState(false);
  const { pathname, search = "", hash = "" } = useLocation();

  const isTrendsOpenPath = pathname === TRENDS_OPEN_ROUTE;

  const params = new URLSearchParams(decodeURIComponent(search));
  const [link, setLink] = useState("");

  useEffect(() => {
    let returnURL = params.get("returnURL");
    if (returnURL) {
      returnURL = window.location.origin + returnURL;
    } else {
      returnURL = window.location.href;
    }
    setLink(returnURL);
  }, [search]);

  useEffect(() => {
    let status = window.sessionStorage.getItem("app_redirect_modal");
    if (!status || status != "true") {
      setShowNetworkToast(true);
    }
  }, []);

  const handleClose = () => {
    setShowNetworkToast(false);
    updateStatusINSessionStorage();
  };

  const handleGetAppButton = async () => {
    let finalLink = "https://link.docflix.com/byAN";
    // if (link) {
    //   finalLink = `https://link.docflix.com/?link=${encodeURIComponent(
    //     link
    //   )}&apn=${BUNDLE_ID}&ibi=${iosAppStoreId}&isi=${BUNDLE_ID}&efr=1`;
    // }
    let obj = {
      "Embeded Link": finalLink,
    };
    addGAWithNoUser(GET_APP_EVENT, obj);
    addCleverTapAnalytics(GET_APP_EVENT, obj);
    window.open(finalLink);
    updateStatusINSessionStorage();
  };

  return isMobileDevice && showToast && !isTrendsOpenPath ? (
    <div className={`${styles.container}`}>
      <div className="animate__animated animate__fadeInUp">
        <div className={styles.box}>
          <div className={styles.btnContainer}>
            <img
              src={close}
              className={styles.closeIcon}
              onClick={handleClose}
            />
            <p className={styles.title}>Docflix is better on the app</p>
            <p
              className={`${styles.btn} ${styles.getApp}`}
              onClick={handleGetAppButton}
              role="button"
            >
              GET APP
            </p>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default AppInstallPopUp;

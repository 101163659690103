import _get from './get';

// Import Managers
import VideoLikesManager from './likes';
import VideoRatingManager from './ratings';
import VideoTranscriptManager from './transcript';

const VideoManager = {
	_get,
	VideoLikesManager,
	VideoRatingManager,
	VideoTranscriptManager
};

export default VideoManager;

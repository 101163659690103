export const VIDEO_COLLECTION = "videos";
export const REELS_COLLECTION = "reels";
export const LIKES_COLLECTION = "likes";
export const RATINGS_COLLECTION = "ratings";
export const TRANSCIPT_COLLECTION = "transcript";
export const MEDIA_METADATA_COLLECTION = "MediaMetaData";
export const DETAILS_COLLECTION = "details";
export const BANNER_COLLECTION = "banners";
export const BACKSTAGE_COLLECTION = "backstage";
export const BACKSTAGE_COLLECTION_OUR_EXPERTS = "our_experts";
export const SPEAKERS_COLLECTION = "speakers";
export const SPEAKER_FOLLOWER_COLLECTION = "SpeakerFollower";

export const USER_COLLECTION = "profiles";
export const USER_METADATA_COLLECTION = "UserMetaData";
export const USER_PREFERENCE_COLLECTION = "UserPreferences";
export const USER_MEDIA_METADATA_COLLECTION = "UserMediaMetaData";
export const USER_BOOKMARKS = "UserBookmarks";
export const USER_NOTES = "UserNotes";
export const USER_WATCH_LATER = "UserWatchLater";

export const SERIES_COLLECTION = "series";
export const SEASON_COLLECTION = "seasons";

export const DISLIKES_COLLECTION = "dislikes";

export const WEBINAR_COLLECTION = "webinar";
export const WEBINAR_ATTENDED_COLLECTION = "webinar_attended";
export const AGENDA_COLLECTION = "agendas";
export const POLLS_COLLECTION = "polls";
export const POLLS_RESPONSE_COLLECTION = "pollsresponse";
export const QA_COLLECTION = "qa";
export const QA_RESPONSE_COLLECTION = "qaresponse";
export const USER_FEEDBACK_COLLECTION = "userFeedback";
export const RESOURCES_COLLECTION = "resources";
export const LIVE_REACTION_COLLECTION = "liveReaction";

export const DIGITAL_AVATAR_CONF_COLLECTION = "digitalAvatar";
export const CHAPTERS_COLLECTION = "chapters";

export const DOCUMENTARY_COLLECTION = "documentary";

export const COURSE_COLLECTION = "academy";
export const COURSE_CHAPTERS_COLLECTION = "courseChapter";
export const STUDENTS_COLLECTION = "student";
export const ACADEMY_PROGRESSION_COLLECTION = "academyProgression";
export const ACADEMY_ASSESSMENT_COLLECTION = "academyAssesment";
export const ACADEMY_ASSESSMENT_RECORD_COLLECTION = "assesmentResponse";
export const FORUM_COLLECTION = "forum";
export const FEEDBACK_COLLECTION = "feedback";
export const FEATURED_DOCFLIX_COLLECTION = "featuredDocflix";
export const WEBINAR_REGISTER_COLLECTION = "webinarRegister";
